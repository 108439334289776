// BaseForm.tsx
import React, {useState} from 'react';
import {generateAiImage, getAdditionalProperties} from "../../api";
import './BaseForm.css';
import {getJwtToken} from "../../authutils";

interface BaseFormProps<T> {
    formData: T;
    projectType: string;
    children?: React.ReactNode; // Accepts children
}

interface DynamicField {
    name: string;
    label: string;
    value: string;
    options: [];
    type: string; // 'text', 'number', etc.
    placeholder: string;
}

interface NerdicImageMetadata {
    title: string;
    originalPrompt: string;
    revisedPrompt: string;
    description: string;
    dimensions: string;
    date: string;
    author: string;
}

const BaseForm = <T, >({formData, projectType, children}: BaseFormProps<T>) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [step, setStep] = useState<number>(1);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    // State for dynamically generated form (second step)
    const [dynamicFields, setDynamicFields] = useState<DynamicField[]>([]);

    // State for storing user input from dynamic fields
    const [dynamicFormData, setDynamicFormData] = useState<{ [key: string]: string }>({});
    const [metadata, setMetadata] = useState<NerdicImageMetadata>({
        title: "Step 3: Generated Image",
        originalPrompt: "",
        revisedPrompt: "",
        description: "A stunning landscape view of mountains during sunset.",
        dimensions: "1024x1024",
        date: "July 21, 2023",
        author: "John Doe",
    });

    // Handle dynamic form data change
    const handleDynamicFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setDynamicFormData(prev => ({...prev, [name]: value}));
    };

    const updatePrompts = (originalPrompt: string, revisedPrompt: string) => {
        setMetadata((prevMetadata) => ({
            ...prevMetadata,
            originalPrompt: originalPrompt,
            revisedPrompt: revisedPrompt,
        }));
    };

    // Function to move to the next step
    const nextStep = async () => {
        setIsLoading(true);
        if (step === 1) {

            // Fetch AI-generated form fields based on basic info
            let token = await getJwtToken();
            try {
                const aiGeneratedFields = await getAdditionalProperties(token, formData, projectType);
                setDynamicFields(aiGeneratedFields);
                setStep(step + 1);
            } catch (err) {
                // Set the error message if fetch fails
                setError(err instanceof Error ? err.message : 'An unknown error occurred');
            } finally {
                setIsLoading(false); // Stop loading when done
            }
        } else if (step === 2) {
            try {
                let token = await getJwtToken();
                // Fetch AI-generated image based on form data
                const generatedImage = await generateAiImage(token, {
                    projectData: formData,
                    dynamicFields: dynamicFormData
                }, projectType);
                const base64String = generatedImage.base64;
                setImageUrl(`data:image/png;base64,${base64String}`);
                updatePrompts(generatedImage.originalPrompt, generatedImage.revisedPrompt);
                setStep(step + 1);
            } catch (err) {
                // Set the error message if fetch fails
                setError(err instanceof Error ? err.message : 'An unknown error occurred');
            } finally {
                setIsLoading(false); // Stop loading when done
            }
        }
    };

    return (
        <>
            <div className="form-container">
                <h2>Create your {projectType} Design Project</h2>
                {isLoading && (
                    <div>Loading...</div>
                )}
                {error && (
                    <p style={{color: 'red'}}>An error occurred: {error}</p>
                )}
                {!isLoading && step === 1 && children && (
                    <div className="form-content">
                        {children}
                        <button onClick={nextStep}>Next</button>
                    </div>
                )}

                {!isLoading && step === 2 && (
                    <div className="form-content">
                        <h3>Step 2: Additional Information (AI-Generated)</h3>
                        {dynamicFields.map((field) => (
                            <>
                                <label>{field.label}:</label>
                                {field.type === "option" ? (
                                    <select
                                        name={field.name}
                                        id={field.name}
                                        value={field.value}
                                        onChange={handleDynamicFormChange}
                                        required
                                    >
                                        {field.options.map((option) => (
                                            <option value={option}>{option}</option>
                                        ))}
                                    </select>

                                ) : (
                                    <input
                                        placeholder={field.placeholder}
                                        type={field.type}
                                        name={field.label}
                                        value={dynamicFormData[field.label] || ''}
                                        onChange={handleDynamicFormChange}
                                    />)}
                            </>
                        ))}
                        <button onClick={nextStep}>Next</button>
                    </div>
                )}
            </div>
            <div className="result-container">
                {!isLoading && step === 3 && (
                    <div className="container">
                        {/* Metadata on the left (for desktop) or below (for mobile) */}
                        <div className="metadata">
                            <h2>{metadata.title}</h2>
                            <p><strong>Original Prompt:</strong> {metadata.originalPrompt}</p>
                            <br/>
                            <p><strong>Revised Prompt:</strong> {metadata.revisedPrompt}</p>
                            <br/>
                            <p><strong>Description:</strong> {metadata.description}</p>
                            <p><strong>Dimensions:</strong> {metadata.dimensions}</p>
                            <p><strong>Date:</strong> {metadata.date}</p>
                            <p><strong>Author:</strong> {metadata.author}</p>
                        </div>

                        {/* Image on the right or above (for mobile) */}
                        <div className="image">
                            {imageUrl ? <img src={imageUrl} alt="AI generated"/> : <p>Generating image...</p>}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default BaseForm;