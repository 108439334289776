const apiUrl = process.env.REACT_APP_API_URL;

export const fetchAiImage = async (category) => {
    const response = await fetch(`${apiUrl}/api/ai/image/`+category, {
        method: 'GET',
        headers: {
            'Content-Type': 'image/png',
        },
        credentials: 'include', // Ensures cookies are sent with the request
    });
    return response.arrayBuffer();
};

export const listImages = async category => {
    const response = await fetch(`${apiUrl}/api/images/${category}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include', // Ensures cookies are sent with the request
    });
    return response.json();
};

export const fetchThumbnail = async (key, category) => {
    const response = await fetch(`${apiUrl}/api/image/${category}/thumbnail/${key}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'image/png',
        },
        credentials: 'include', // Ensures cookies are sent with the request
    });
    return response.arrayBuffer();
}

export const fetchFullImage = async (category, key) => {
    const response = await fetch(`${apiUrl}/api/image/${category}/full/${key}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'image/png',
        },
        credentials: 'include', // Ensures cookies are sent with the request
    });
    return response.arrayBuffer();
}

export const createBasicKnittingProject = async (token, formData) => {
    const response = await fetch(`${apiUrl}/admin/create/knitting/basic`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include'
    });
    return response.json();
};


export const createWoodworkingProject = async (token, formData) => {
    const response = await fetch(`${apiUrl}/admin/create/woodworking`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include'
    });
    return response.arrayBuffer();
};

export  const createKnittingProject = async (token, formData) => {
    const response = await fetch(`${apiUrl}/admin/create/basic`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include'
    });
    return response.json();
}

export  const fetchAIForm = async (token, basicInfo) => {
    const response = await fetch(`${apiUrl}/admin/create/basic`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(basicInfo),
        credentials: 'include'
    });
    return response.json();
}

export  const getAdditionalProperties = async (token, basicInfo, projectType) => {
    const response = await fetch(`${apiUrl}/admin/additionalProperties/${projectType}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(basicInfo),
        credentials: 'include'
    });
    return response.json();
}

export const generateAiImage = async (token, formData, projectType) => {
    const response = await fetch(`${apiUrl}/admin/create/${projectType}/image`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include'
    });
    return response.json();
}


